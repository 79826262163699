<template>
<div class="market-insights full-width" :key="kpi + render">    
    <div class="columns is-gapless">
        <div class="column is-2-desktop is-4-tablet is-12-mobile fixed bg-white">
            <div class="kpi-box-wrapper inline-block float-left relative">

                <button class="button retail-manager is-fullwidth ui-rounded">
                    <div v-if="!!first_name && !!last_name">Retail Manager:<br/>{{first_name}} {{last_name}}</div>
                    <div v-else>No retail manager set.</div>
                </button>

                <div class="kpi-areas ui-rounded">
                    <div class="columns is-gapless is-multiline m0">
                        <div class="column is-12" v-for="(kpi, index) in kpi_options" :key="index">
                            <div class="kpi-area">
                                <button class="button" @click="update_kpi(kpi)" :style="render_background(kpi)">{{kpi}}</button>
                            </div>
                        </div>
                        <div class="column is-12">
                            <DataBox
                                v-if="db_kpi_data"
                                :data="db_kpi_data"
                                :versions="version_obj"
                                class="data-box ui-rounded"
                            />
                            <div v-else class="data-box ui-rounded">
                                No data has been found for {{kpi_formatted}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column">
            <div class="columns is-gapless buttons has-addons edit-buttons inline-block ui-rounded no-left-margin is-mobile overflow-visible">
                <div class="column">
                    <div class="kpi-title">
                        {{kpi_formatted}}
                    </div>
                </div>

                <div class="column is-narrow is-offset">
                    <button class="button" v-if="$store.getters.check_access(['country-insights', kpi, '*', 'r'])" @click="selected = 'current'" :class="{'is-active': selected === 'current'}">
                        Current <i class="far fa-comments hidden-on-mobile ml"></i>
                    </button>
                    <button class="button" v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w']) && $store.state.global.show_edit" @click="selected = 'new'" :class="{'is-active': selected === 'new'}">
                        New
                    </button>
                    <button class="button" v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w']) && $store.state.global.show_edit" @click="selected = 'draft'" :class="{'is-active': selected === 'draft'}">
                        Draft
                    </button>
                    <button class="button" v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w']) && $store.state.global.show_edit" @click="selected = 'scheduled'" :class="{'is-active': selected === 'scheduled'}">
                        Scheduled
                    </button>
                    <button class="button" v-if="$store.getters.check_access(['country-insights', kpi, '*', 'r'])" @click="selected = 'archived'" :class="{'is-active': selected === 'archived'}">
                        Archived<i class="fas fa-archive hidden-on-mobile ml"></i>
                    </button>
                    <button class="button" @click="toggle_print_options">
                        <i class="fas fa-print"></i>
                    </button>
                    <button
                        class="button presentation-mode ft-tooltip-bottom"
                        v-if="$store.getters.check_access(['country-insights', kpi, '*', 'w'])"
                        @click="toggle_presentation_mode()" :class="{'active': $store.state.global.show_edit}"
                        :tooltiptext="$store.state.global.show_edit ? 'Finish Editing' : 'Edit'"
                    >
                        <i class="fas fa-edit"></i>
                        <i class="fas fa-slash absolute" v-show="$store.state.global.show_edit"></i>
                    </button>
                </div>
            </div>

            <div class="content">
                <BrowserNotice />
                <div v-if="!edit_mode">
                    <div v-if="selected !== 'new'">
                        <div v-if="comments.length > 0">
                            <CommentWarning
                                v-if="(kpi === 'introduction' ? comments.length > 2 : comments.length > 1) && $store.getters.check_access(['comments', '*', 'w']) && selected === 'current'"
                                        :commentLimit="kpi === 'introduction' ? 'two' : 'one'"
                                    />
                            <div class="comment-container" :class="comments.length - 1 !== i ? 'comment-border' : ''" v-for="(comment, i) in comments" :key="i">
                                <div class="comment-meta columns">
                                    <div class="column">
                                        {{comment.u_display_name}}
                                        <div style="display: inline-flex;">
                                            <div v-if="comment.updated_by" class="ft-tooltip" :tooltiptext="comment.e_display_name">|Edited</div>
                                            <div v-if="comment.author_id" class="ft-tooltip" :tooltiptext="comment.a_display_name">|Posted By</div>
                                        </div>
                                    </div>
                                    <div v-if="comment.title" class="column text-bold">{{comment.title}}</div>
                                    <div class="column is-one-quarter">{{comment.scheduled_date.split('T')[0]}} - {{comment.comment_lifetime.split('T')[0]}}</div>
                                </div>
                                <div class="comment-content text-left ProseMirror" v-html="comment.content"></div>
                                <div class="button-container" v-if="$store.getters.check_access(['comments', 'edit', '*', 'w']) &&  $store.state.global.show_edit">
                                    <div class="button edit-button is-half" @click="comment_to_edit = comments[i], toggle_edit_mode()">
                                        Edit
                                    </div>
                                    <div v-if="selected !== 'archived'" class="button edit-button is-half" @click="comment_to_edit = comments[i], change_status('ARCHIVED')">
                                        Archive
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="mt">
                            No {{selected}} observations could be found
                        </div>
                    </div>
                    <div v-else>
                        <New
                            :kpi="kpi"
                            @toggle-edit-mode="toggle_edit_mode"
                            @updated="edit_updated"
                        />
                    </div>
                </div>
                <div v-else class="mt">
                    <Edit
                        :comment-to-edit="comment_to_edit"
                        :status="selected"
                        @toggle-edit-mode="toggle_edit_mode"
                        @updated="edit_updated"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="blurry background-image"></div>
</div>
</template>

<script>
import Alert from '@/components/ui/Alert.vue';
import BrowserNotice from '@/components/bespoke/home/BrowserNotice.vue';
import CommentWarning from '@/components/bespoke/commenting/CommentWarning.vue';
import DataBox from '@/components/bespoke/market-insights/DataBox.vue';
import Edit from '@/components/bespoke/country-insights/Edit.vue';
import New from '@/components/bespoke/country-insights/New.vue';
import kpi_area_mapping from '@/assets/scripts/kpi-area-mapping.js';
import kpi_data_mapping from '@/assets/scripts/kpi-data-mapping.js';

export default {
    components: {
        Alert,
        BrowserNotice,
        CommentWarning,
        DataBox,
        Edit,
        New
    },
    props: {
        kpi: String,
        market: String
    },
    data: function() {
        return {
            first_name: null,
            last_name: null,

            kpi_options: ['Introduction', 'Three Roads', 'Brand', 'Business', 'Customer', 'Finance', 'People', 'Sustainability', 'Process'],
            kpi_values: ['introduction', 'three-roads', 'brand', 'business', 'customer', 'financial', 'people', 'sustainability', 'process'],
            kpi_data: null,
            
            render: false,
            selected: 'current',
            filters: {
				order_by: null,
				search: null
            },
            print_options_visible: false,
            version_obj: {},
            edit_mode: false,
            comments: [],
            comment_to_edit: null,

            name_list: [],
            name_obj: {},
            temp_path: null,
            temp_parent_path: null
        }
    },
    mounted: function() {
        this.$store.commit('update_market', this.market);
        this.$store.state.commenting.width = 0;
        if (this.$store.getters.market === null || !this.kpi || !this.market) {
            this.$router.push(`/country-insights/${this.kpi}/${this.$store.state.selected.market}`);
        } else {
            this.load_kpi_data();
            this.get_retail_manager();
            this.load_comments();
        }
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        change_status: function(status) { 
            this.$chttp.post(`/comments/edit`, {
                id: this.comment_to_edit.comment_id, 
                status: status
            }).then(
                succ => {
                    this.edit_updated(status);
                },
                this.$helper.error
            );
        },
        update_view: function(view) {
            this.selected = view;
        },
        update_kpi: function(kpi) {
            let index = this.kpi_options.indexOf(kpi);
            let kpi_name = this.kpi_values[index];
            this.edit_mode = false;
            this.$router.push(`/country-insights/${kpi_name}/${this.market}`);
        },
        edit_updated: function(status) {
            this.edit_mode = false;
            if (status === 'LIVE') {
                this.selected = 'current';
            } else if (status !== 'DELETED' && 'ARCHIVED') {
                this.selected = status.toLowerCase();
            }            
            this.load_comments();
        },
        toggle_presentation_mode: function() {
            this.$store.state.global.show_edit = !this.$store.state.global.show_edit
            if (this.edit_mode) {
                this.edit_mode = false;
            } 
        },
        load_comments: function() {
			this.comments = [];
            let to_load = this.$helper.location();
            to_load.status = (this.selected === 'current' ? 'live' : this.selected).toUpperCase();
			to_load.filters = {
				order_by: this.selected === 'archived' ? this.filters.order_by : null,
				search: this.selected === 'archived' ? this.filters.search : null
            };
            
			this.$chttp.post('/comments/load',
				to_load
			).then(
				succ => {
					if (succ.data) {
                        let d = succ.data;
                        if (d.length > 0) {
                            this.comments = d;
                        }                     
					}
				},
				error => {}
			);
        },
        load_kpi_data: function() {
            this.$http.post('/landing-page/all', {market: this.$helper.location().market_area}).then(
                succ => {
                    this.kpi_data = {};
                    succ.data.forEach(item => {
                        if (!('fp_data') in item) {
                            return;
                        }
                        item = item.fp_data;
                        let kpi_name = Object.keys(item).pop();
                        this.kpi_data[kpi_name] = {};
                        
                        item[kpi_name].forEach(kpi_area => {
                            let kpi_area_name = Object.keys(kpi_area).pop();
                            let kpi_data = kpi_area[kpi_area_name][0][this.market];
                            
                            if (!kpi_data) return;

                            this.kpi_data[kpi_name][kpi_area_name] = {
                                'Year Goal': kpi_data.YG ? kpi_data.YG : null,
                                'YTD': kpi_data.YTD ? kpi_data.YTD : null
                            }
                        });
                    });
                    if (Object.keys(this.kpi_data).length > 0) {
                        this.add_filter_and_links();
                    }
                },
                this.$helper.error
            );
            
        },
        scan_mapped_areas: function(mapped_kpi, kpi_datum, kpi, use_parent_path = false) {
            if ('areas' in mapped_kpi) {
                mapped_kpi.areas.forEach(mapped_kpi_child => this.scan_mapped_areas(mapped_kpi_child, kpi_datum, kpi));
            } else if ('alt' in mapped_kpi) {
                this.temp_parent_path = mapped_kpi.location || mapped_kpi.path;
                mapped_kpi.alt.forEach(alt => this.scan_mapped_areas(alt, kpi_datum, kpi, true));
            } else if (mapped_kpi.name.toLowerCase() === kpi.toLowerCase()) {
                if (this.kpi_data[kpi_datum][kpi]) this.temp_path = use_parent_path ? this.temp_parent_path : mapped_kpi.location || mapped_kpi.path;
            }
        },
        empty_temps: function() {
            this.temp_path = null;
            this.temp_parent_path = null;
        },
        add_filter_and_links: function() {
            Object.keys(this.kpi_data).forEach(kpi_datum => {
                Object.keys(this.kpi_data[kpi_datum]).forEach(kpi => {
                    this.empty_temps();

                    const matching_mapped_kpi = kpi_data_mapping.find(mapped_kpi => mapped_kpi.name === kpi_datum);
                    if (matching_mapped_kpi === undefined) return;

                    this.scan_mapped_areas(matching_mapped_kpi, kpi_datum, kpi);
                    if (this.temp_path) this.kpi_data[kpi_datum][kpi].path = this.temp_path;
                    this.add_version(kpi_datum, this.temp_path);
                });
            });

            this.$http.post(`/last-updated/list`, {kpi_areas: this.name_list}).then(
                succ => {
                    let d = succ.data;
                    d.forEach( name => {
                        if (name.version) {
                            this.version_obj[this.name_obj[name.view]] = name.version;
                        }
                    });
                    this.kpi_render = true;
                },
                this.$helper.error
            );
        },
        add_version: function(kpi_area, path) {
            if (!path) return;
            return new Promise((resolve, reject) => {
                let kpi_name = kpi_area_mapping.find(kpi => path.includes(kpi.path));
                if (kpi_name) {
                    this.name_list.push(kpi_name.name);
                    this.name_obj[kpi_name.name] = path;
                }
            });
        },
        get_retail_manager: function() {
            this.first_name = null;
            this.last_name = null;
            this.$http.get(`admin/retail-managers/load/${this.$helper.location().market_area}`).then(
                succ => {
                    if (!succ.data || !succ.data[0]) return;
                    const [{ first_name, last_name }] = succ.data;
                    this.first_name = first_name;
                    this.last_name = last_name;
                },
                this.$helper.error
            );
        },
        toggle_edit_mode: function() {
            if (this.selected === 'new') {
                this.edit_mode = false;
                this.selected = 'current';
            } else {
                this.edit_mode = !this.edit_mode;
            }
        },
        toggle_print_options: function() {
            this.$root.$emit('ci_open_modal');
        },
        render_background: function(kpi) {
            return {
                'border-right': `0.5rem solid ${this.colors[kpi === 'Introduction'? 'blue' : 'yellow']}`
            }
        }
    },
    computed: {
        background_image: function() {
            let image = this.images[kpi];
            let kpi = this.kpi
                .toLowerCase()
                .replace('financial', 'finance')
                .replace('three roads', 'three-roads');
            
            return {
                'background-image': `url(${this.images[kpi]})`,
                'background-size': 'cover',
                'background-position': 'center',
                'height': '100vh',
                'filter': 'blur(8px)',
                'position': 'fixed',
                'top': 0,
                'left': 0,
                'width': '100vw',
                'z-index': '-1',
            }
        },
        colors: function() {
            return this.$store.state.colors;
        },
        db_kpi_data: function() {
            if (this.kpi_data) {
                return this.kpi_data[this.kpi_formatted]
            }
            return false;
        },
        kpi_formatted: function() {
            return this.kpi
                .replace(/-/g, " ")
                .replace(/three/g, "3")
                .replace(/financial/, "finance")
                .replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
        },
        print_options: function() {
            return {
                country: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-${this.market}`,
                all: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-all`,

                scheduled: {
                    country: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-${this.market}-scheduled`,
                    all: `${process.env.VUE_APP_BASE_URL}/download/pdf/output-all-scheduled`
                }
            }
        },
        stored_market: function() {
            return this.$store.getters.market;
        },
    },
    watch: {
        kpi: function() {
            this.load_comments();
        },
        selected: function() {
            if (this.selected !== 'new') {
                this.load_comments();
            }
        },
        stored_market: function(newVal, oldVal) {
            this.$router.push(`/country-insights/${this.kpi}/${newVal}`);
            this.get_retail_manager(newVal);
            this.load_kpi_data();
            this.load_comments();
        },
        market: function(newVal, oldVal) {
            this.$store.commit('update_market', newVal);
            this.get_retail_manager(newVal);
            this.load_kpi_data();
            this.load_comments();
        }
    }
}

</script>

<style scoped>

.comment-container {
    background-color: white;
    margin: 0.5rem;
    border: 0.5rem solid #FFF;
    border-radius: 0;
    -webkit-box-shadow: 2px 2px 2px #313131;
    box-shadow: 0px 1px 2px #969696;
}
.comment-content {
    padding: 1rem;
}

.comment-meta {
    margin: 0 !important;
}
.market-insights .blurry.background-image {
    background-color: #EAEAEA;
    background-size: cover;
    background-position: center;
    height: calc(100vh - 3.5rem);
    filter: blur(8px);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: -1;
}

.market-insights .data-box {
    /* bottom: 0; */
    /* position: absolute; */
}

.market-insights .edit-buttons {
    background-color: #FFF;
    border-left: none;
    border-right: none;
    margin-bottom: 0 !important;
    overflow-x: auto;
    padding: 0;
    width: 100%;
}

.market-insights .edit-buttons button {
    background-color: #FFF;
    border: none;
    border-radius: 0;
    height: 3rem;
    margin: 0;
    min-width: 5rem
}

.market-insights .edit-buttons button:hover {
    background-color: #CCC;
    color: #FFF;
}

.market-insights .edit-buttons button.is-active {
    background-color: #CCC;
    color: #000;
}

.market-insights .kpi-area {
    margin: 0.5rem;
}

.market-insights .kpi-area button {
    background-color: #FFF;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 2px #b1b1b1;
    color: #000;
    margin-bottom: 0.5rem;
    width: 100%;
}

.market-insights .kpi-area button:hover {
    background-color: #f2f2f2;
    box-shadow: none;
    color: #CCC;
}

.market-insights .kpi-area:last-child button {
    margin-bottom: 0;
}

.market-insights .kpi-areas {
    background-color: #FFF;
    overflow: auto;
}

.market-insights .kpi-box-wrapper {
    height: calc(100vh - 3.5rem);
    position: relative;
    width: 100%;
}

.market-insights .kpi-title {
    color: #000;
    float: left;
    font-size: 2rem;
    height: 100%;
}

.market-insights .presentation-mode:hover {
    background-color: #FFF !important;
    color: #000 !important;
}

.market-insights .print.button {
    border-radius: 0;
}

.market-insights .retail-manager {
    border: 0;
    cursor: text;
    height: 3rem;
    user-select: text;
}

.market-insights .toolbar {
    border-left: none;
    border-right: none;
}

.market-insights .ui-rounded {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 0;
    padding: 0.5rem;
    width: 100%;
}

.market-insights .ui-rounded.no-left-margin {
    padding: 0.5rem;
}
</style>