import { render, staticRenderFns } from "./CountryInsights.vue?vue&type=template&id=32635184&scoped=true"
import script from "./CountryInsights.vue?vue&type=script&lang=js"
export * from "./CountryInsights.vue?vue&type=script&lang=js"
import style0 from "./CountryInsights.vue?vue&type=style&index=0&id=32635184&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32635184",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32635184')) {
      api.createRecord('32635184', component.options)
    } else {
      api.reload('32635184', component.options)
    }
    module.hot.accept("./CountryInsights.vue?vue&type=template&id=32635184&scoped=true", function () {
      api.rerender('32635184', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/market-insights/CountryInsights.vue"
export default component.exports